import { Component, HostListener, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MENU } from 'src/app/app-routing.module';
import { MenuItem } from 'src/app/models/menu-item';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { Location } from 'src/app/models/location';
import { RequestService } from 'src/app/services/request.service';
import { UntypedFormControl } from '@angular/forms';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { SearchService } from 'src/app/services/search.service';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { NavigationEnd, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ResetPasswordDialogComponent } from '../reset-password-dialog/reset-password-dialog.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from 'src/app/services/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { RequestsService } from 'src/app/services/requests.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnInit {
  sideBarOpen = true;
  desktopViewWidth: number = 1100;
  faBell = faBell;
  mongoDBIdRegEx = /^[a-fA-F0-9]{24}$/;
  drawerMode: 'side' | 'over' = 'side';

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    if (width < this.desktopViewWidth) {
      this.drawerMode = 'over';
      if (this.sideBarOpen) {
        this.sideBarOpen = false;
      }
    } else {
      this.drawerMode = 'side';
      if (!this.sideBarOpen) {
        this.sideBarOpen = true;
      }
    }
  }
  changeRoute = new Subject<string>();
  isHome = false;
  isReport = false;
  isDashboard = false;
  isAccountant = false;
  isProperties: boolean = false;
  isClients: boolean = false;

  menu: any;
  locations: Observable<Location[]> | any;
  temLocation: Location | any;
  user_role: string = '';
  first_name: string = '';
  last_name: string = '';
  user_id: string = '';
  fileUrl: string;
  locationForm = new UntypedFormControl('');
  notifis: any;
  defaultLanguage: string = 'en';
  isLangFromLocal: string = '';
  isHidden = false;
  totalRequests: any;
  subscription_start: any;
  subscription_end: any;
  now = new Date().toISOString();

  constructor(
    public authService: AuthService,
    private locationService: LocationService,
    private localStorageService: LocalStorageService,
    private searchService: SearchService,
    private router: Router,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public translateService: TranslateService,
    private _requestService: RequestsService,
    private _userService: UserService
  ) {
    iconRegistry.addSvgIcon(
      'dashboard',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/dashboard.svg')
    );
    iconRegistry.addSvgIcon(
      'organization',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/organization.svg')
    );
    iconRegistry.addSvgIcon(
      'user-outline',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/user-outline.svg')
    );
    iconRegistry.addSvgIcon(
      'open-door',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/open-door.svg')
    );
    iconRegistry.addSvgIcon(
      'report',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/report.svg')
    );
    iconRegistry.addSvgIcon(
      'subscription',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/subscription.svg')
    );
    iconRegistry.addSvgIcon(
      'bank',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/bank.svg')
    );
    iconRegistry.addSvgIcon(
      'tenant',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/tenants.svg')
    );
    iconRegistry.addSvgIcon(
      'agency',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/agency.svg')
    );
    iconRegistry.addSvgIcon(
      'accountant',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/accountant.svg')
    );
    iconRegistry.addSvgIcon(
      'income',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/income.svg')
    );
    iconRegistry.addSvgIcon(
      'request',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/request.svg')
    );
    iconRegistry.addSvgIcon(
      'contract',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/contract.svg')
    );
    iconRegistry.addSvgIcon(
      'announcement',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/announcement.svg')
    );
    iconRegistry.addSvgIcon(
      'expense',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/expense.svg')
    );
    iconRegistry.addSvgIcon(
      'client',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/client.svg')
    );
    iconRegistry.addSvgIcon(
      'property',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/property.svg')
    );
    iconRegistry.addSvgIcon(
      'redtimer',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/redtimer.svg')
    );
    iconRegistry.addSvgIcon(
      'danger',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/danger.svg')
    );
    iconRegistry.addSvgIcon(
      'listing',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/listing_logo.svg')
    );
    iconRegistry.addSvgIcon(
      'communities',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/community.svg')
    );
    iconRegistry.addSvgIcon(
      'moderate',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/imgs/moderation-logo.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'archive_calendar',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/date02.svg')
    );

    //todo: request user to verify role or permission
    this.menu = MENU;
    // const menuOperation: MenuItem[] = MENU.filter(
    //   (x) =>
    //     x.type == 'operation' &&
    //     x.roles &&
    //     x.roles.includes(authService.userRole)
    // );
    const menuSuperAdmin: MenuItem[] = MENU.filter(
      (x) =>
        x.type == 'super_admin' &&
        x.roles &&
        x.roles.includes(authService.userRole)
    );
    const menuProperties: MenuItem[] = MENU.filter(
      (x) =>
        x.type == 'properties' &&
        x.roles &&
        x.roles.includes(authService.userRole)
    );
    const menuClients: MenuItem[] = MENU.filter(
      (x) =>
        x.type == 'clients' && x.roles && x.roles.includes(authService.userRole)
    );
    const menuInfo: MenuItem[] = MENU.filter(
      (x: any) =>
        x.type == 'information' &&
        x.roles &&
        x.roles.includes(authService.userRole)
    );
    const menuIncome: MenuItem[] = MENU.filter(
      (x) =>
        x.type == 'incomes' && x.roles && x.roles.includes(authService.userRole)
    );
    const menuExpense: MenuItem[] = MENU.filter(
      (x) =>
        x.type == 'expenses' &&
        x.roles &&
        x.roles.includes(authService.userRole)
    );
    const menuRequest: MenuItem[] = MENU.filter(
      (x) =>
        x.type == 'requests' &&
        x.roles &&
        x.roles.includes(authService.userRole)
    );
    const menuContract: MenuItem[] = MENU.filter(
      (x) =>
        x.type == 'contracts' &&
        x.roles &&
        x.roles.includes(authService.userRole)
    );
    const menuAnnouncement: MenuItem[] = MENU.filter(
      (x) =>
        x.type == 'announcements' &&
        x.roles &&
        x.roles.includes(authService.userRole)
    );
    const menuAccountant: MenuItem[] = MENU.filter(
      (x) =>
        x.type == 'accounting' &&
        x.roles &&
        x.roles.includes(authService.userRole)
    );
    const menuReport: MenuItem[] = MENU.filter(
      (x) =>
        x.type == 'reports' && x.roles && x.roles.includes(authService.userRole)
    );
    const menuListing: MenuItem[] = MENU.filter(
      (x) =>
        x.type == 'listing' && x.roles && x.roles.includes(authService.userRole)
    );
    const menuSetting: MenuItem[] = MENU.filter(
      (x: any) =>
        x.type == 'settings' &&
        x.roles &&
        x.roles.includes(authService.userRole)
    );
    const menuModeration: MenuItem[] = MENU.filter(
      (x: any) =>
        x.type == 'moderate' &&
        x.roles &&
        x.roles.includes(authService.userRole)
    );

    const menuPasses: MenuItem[] = MENU.filter(
      (x) =>
        x.type == 'passes' && x.roles && x.roles.includes(authService.userRole)
    );

    const menuScanErrors: MenuItem[] = MENU.filter(
      (x) =>
        x.type == 'scan-errors' &&
        x.roles &&
        x.roles.includes(authService.userRole)
    );

    this.menu = {
      superadmin: menuSuperAdmin,
      information: menuInfo,
      income: menuIncome,
      expense: menuExpense,
      request: menuRequest,
      contract: menuContract,
      announcement: menuAnnouncement,
      accountant: menuAccountant,
      reports: menuReport,
      listing: menuListing,
      settings: menuSetting,
      properties: menuProperties,
      clients: menuClients,
      moderation: menuModeration,
      passes: menuPasses,
      scanErrors: menuScanErrors,
    };
    this.user_role = authService.userRole;
    this.first_name = this.localStorageService.get(
      LocalStorageEnum.user_firstname
    );
    this.last_name = this.localStorageService.get(
      LocalStorageEnum.user_lastname
    );
    this.user_id = this.localStorageService.get(LocalStorageEnum.user_id);
    this.subscription_start = this.localStorageService.get(
      LocalStorageEnum.subscription_start
    );
    this.subscription_end = this.localStorageService.get(
      LocalStorageEnum.subscription_end
    );

    this.fileUrl = this.localStorageService.get(LocalStorageEnum.user_profile);
    if (this.localStorageService.get(LocalStorageEnum.organization_id)) {
      this.getLocations();
    }
    const locationId = this.localStorageService.get(
      LocalStorageEnum.location_id
    );
    if (locationId) this.searchService.emitText(locationId);
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.isHome =
          event.url === '/information' ||
          event.url === '/' ||
          this.checkIfHomeAndShouldBeFull(event);
        this.isReport = event.url === '/reports';
        this.isDashboard = event.url === '/dashboard';
        this.isAccountant = event.url === '/accounting';
        this.isProperties = event.url === '/properties';
        this.isClients = event.url.startsWith('/clients');
      }
    });
    this.getNotifications();
  }

  ngOnInit(): void {
    this.onResize(window.innerWidth);
    this.translateService.addLangs(['en', 'km']);
    this.isLangFromLocal = this.localStorageService.get(LocalStorageEnum.lang);
    if (
      this.isLangFromLocal === null ||
      this.isLangFromLocal === undefined ||
      this.isLangFromLocal === ''
    ) {
      const language: any = this.defaultLanguage;
      this.translateService.use(language);
      this.translateService.setDefaultLang(language);
      this.localStorageService.set(LocalStorageEnum.lang, language);
    } else {
      this.translateService.use(this.isLangFromLocal);
      this.translateService.setDefaultLang(this.isLangFromLocal);
    }
    this.getPendingRequests({});
  }

  number(num: string) {
    return parseInt(num);
  }

  expired = (date: string) => {
    const currentDate = new Date();
    const endDate = new Date(date);
    return endDate.getTime() < currentDate.getTime();
  };

  async changeLanguagePreference() {
    const currentLanguage = this.localStorageService.get(LocalStorageEnum.lang);
    const toLang = currentLanguage === 'en' ? 'km' : 'en';
    const data: any = {
      lang: toLang,
    };
    try {
      await this._userService
        .updateLanguageForUser(this.user_id, data)
        .toPromise();
      this.translateService.use(toLang);
      this.translateService.setDefaultLang(toLang);
      this.localStorageService.set(LocalStorageEnum.lang, toLang);
    } catch (err) {
      console.error(err);
      this.translateService.use(currentLanguage);
      this.translateService.setDefaultLang(currentLanguage);
    }
  }

  getPendingRequests = async (data: any) => {
    try {
      const res: any = await this._requestService
        .getPendingRequests(data)
        .toPromise();
      if (res >= 1) {
        this.totalRequests = res;
      } else {
        this.totalRequests = '';
      }
    } catch (error) {
      console.error('Fetching pending request error', error);
    }
  };

  checkIfHomeAndShouldBeFull(event: NavigationEnd) {
    const urlSplits = event.url.split('/');
    const lastSplit = urlSplits[urlSplits.length - 1];

    if (
      event.url.startsWith('/information') &&
      event.url.includes('contracts')
    ) {
      if (lastSplit.match(this.mongoDBIdRegEx)) {
        return true;
      } else {
        return false;
      }
    }
    if (
      event.url.startsWith('/information') &&
      event.url.includes('room-move-in')
    ) {
      return true;
    }
    if (
      event.url.startsWith('/information') &&
      event.url.includes('room-move-out')
    ) {
      return true;
    }
    if (
      event.url.startsWith('/contracts/update') ||
      event.url.startsWith('/contracts/detail') ||
      event.url.startsWith('/properties/detail') ||
      event.url.startsWith('/properties/room') ||
      event.url.startsWith('/others')
    ) {
      return true;
    }

    return false;
  }
  getNotifications() {
    this.notificationService.getNotifications().subscribe((res: any) => {
      this.notifis = res.data;
    });
  }

  onMarkAsRead(data: any) {
    let id: any[] = [];
    data.forEach((item: any) => {
      id.push(item._id);
    });
    this.notificationService
      .markAsRead({ notification_ids: id })
      .subscribe((res: any) => {});
  }

  openResetPasswordDialog = () => {
    const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
      width: '450px',
      panelClass: 'custom',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response?.logout) {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    });
  };

  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }

  drawerOpenChangeHandler = (opened: boolean) => {
    this.sideBarOpen = opened;
  };

  getLocations() {
    this.locationService.getLocations().subscribe((res: any) => {
      this.locations = res.data;
      const locationId = this.localStorageService.get(
        LocalStorageEnum.location_id
      );
      if (
        locationId === '' ||
        locationId === 'null' ||
        locationId === null ||
        locationId === undefined
      ) {
        this.temLocation = this.locations[0];
      } else {
        this.temLocation = this.locations.find((obj: Location) => {
          return obj?._id === locationId;
        });
      }
      this.searchService.emitText(this.temLocation?._id);
    });
  }

  onSelectedLocationChange(e: string) {
    this.searchService.emitText(e);
  }

  onClickMenu() {
    if (this.drawerMode == 'over') {
      this.sideBarOpen = false;
    } else {
      this.sideBarOpen = true;
    }
  }
}
