import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ContainerComponent } from './components/container/container.component';
import { UserRoleEnum } from './models/enums/user-role.enums';
import { MenuItem } from './models/menu-item';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'information',
    pathMatch: 'full',
  },
  {
    path: '',
    component: ContainerComponent,
    children: [
      {
        path: 'information',
        data: {
          icon: 'info',
          type: 'information',
          roles: [UserRoleEnum.manager, UserRoleEnum.admin, UserRoleEnum.staff],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'dashboard',
        data: {
          icon: 'dashboard',
          type: 'super_admin',
          roles: [UserRoleEnum.super_admin],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'organizations',
        data: {
          icon: 'organization',
          type: 'super_admin',
          roles: [UserRoleEnum.super_admin],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/organizations/organizations.module').then(
            (m) => m.OrganizationsModule
          ),
      },
      {
        path: 'managers',
        data: {
          icon: 'user-outline',
          type: 'super_admin',
          roles: [UserRoleEnum.super_admin],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/managers/managers.module').then(
            (m) => m.ManagersModule
          ),
      },
      {
        path: 'agents',
        data: {
          icon: 'user-outline',
          type: 'super_admin',
          roles: [UserRoleEnum.super_admin],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/agents/agents.module').then((m) => m.AgentsModule),
      },
      {
        path: 'facilities',
        data: {
          icon: 'open-door',
          type: 'super_admin',
          roles: [UserRoleEnum.super_admin],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/facilities/facilities.module').then(
            (m) => m.FacilitiesModule
          ),
      },
      {
        path: 'subscriptions',
        data: {
          icon: 'subscription',
          type: 'super_admin',
          roles: [UserRoleEnum.super_admin],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/subscriptions/subscriptions.module').then(
            (m) => m.SubscriptionsModule
          ),
      },
      {
        path: 'banks',
        data: {
          icon: 'bank',
          type: 'super_admin',
          roles: [UserRoleEnum.super_admin],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/bank/bank.module').then((m) => m.BankModule),
      },
      {
        path: 'tenants',
        data: {
          icon: 'tenant',
          type: 'super_admin',
          roles: [UserRoleEnum.super_admin],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/tenant/tenant.module').then((m) => m.TenantModule),
      },
      {
        path: 'agencies',
        data: {
          icon: 'agency',
          type: 'super_admin',
          roles: [UserRoleEnum.super_admin],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/agencies/agencies.module').then(
            (m) => m.AgenciesModule
          ),
      },
      {
        path: 'visitor-passes',
        data: {
          icon: 'subscription',
          type: 'super_admin',
          roles: [UserRoleEnum.super_admin],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/visitor-passes/visitor-passes.module').then(
            (m) => m.VisitorPassesModule
          ),
      },
      {
        path: 'passes',
        data: {
          icon: 'subscription',
          type: 'passes',
          roles: [UserRoleEnum.manager],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/passes/passes.module').then((m) => m.PassesModule),
      },
      {
        path: 'scan-errors',
        data: {
          icon: 'subscription',
          type: 'scan-errors',
          roles: [UserRoleEnum.manager],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/pass-logs/pass-logs.module').then(
            (m) => m.PassLogsModule
          ),
      },
      {
        path: 'communities',
        data: {
          icon: 'communities',
          type: 'super_admin',
          roles: [UserRoleEnum.super_admin],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/communities/communities.module').then(
            (m) => m.CommunitiesModule
          ),
      },
      {
        path: 'posts',
        data: {
          type: 'moderate',
          roles: [UserRoleEnum.super_admin],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/posts/posts.module').then((m) => m.PostsModule),
      },
      {
        path: 'comments',
        data: {
          type: 'moderate',
          roles: [UserRoleEnum.super_admin],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/comments/comments.module').then(
            (m) => m.CommentsModule
          ),
      },
      // {
      //   path: 'monthly-records',
      //   data: {
      //     type: 'operation',
      //     roles: [UserRoleEnum.manager, UserRoleEnum.admin],
      //   },
      //   canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import('./routes/monthly-records/monthly-records.module').then(
      //       (m) => m.MonthlyRecordsModule
      //     ),
      // },
      {
        path: 'incomes',
        data: {
          type: 'incomes',
          roles: [UserRoleEnum.manager, UserRoleEnum.admin, UserRoleEnum.staff],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/invoices/invoices.module').then(
            (m) => m.InvoicesModule
          ),
      },
      {
        path: 'expenses',
        data: {
          type: 'expenses',
          roles: [UserRoleEnum.manager, UserRoleEnum.admin, UserRoleEnum.staff],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/expenses/expenses.module').then(
            (m) => m.ExpensesModule
          ),
      },
      {
        path: 'requests',
        data: {
          type: 'requests',
          roles: [UserRoleEnum.manager, UserRoleEnum.admin, UserRoleEnum.staff],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/request/request.module').then(
            (m) => m.RequestModule
          ),
      },
      {
        path: 'contracts',
        data: {
          type: 'contracts',
          roles: [UserRoleEnum.manager, UserRoleEnum.admin, UserRoleEnum.staff],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/contract/contract.module').then(
            (m) => m.ContractModule
          ),
      },
      {
        path: 'announcements',
        data: {
          type: 'announcements',
          roles: [UserRoleEnum.manager, UserRoleEnum.admin, UserRoleEnum.staff],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/announcements/announcements.module').then(
            (m) => m.AnnouncementsModule
          ),
      },
      {
        path: 'accounting',
        data: {
          type: 'accounting',
          icon: 'accountant',
          roles: [UserRoleEnum.manager, UserRoleEnum.admin, UserRoleEnum.staff],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/accountant/accountant.module').then(
            (m) => m.AccountantModule
          ),
      },
      {
        path: 'reports',
        data: {
          type: 'reports',
          icon: 'report',
          roles: [UserRoleEnum.manager, UserRoleEnum.admin, UserRoleEnum.staff],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/reports/reports.module').then(
            (m) => m.ReportsModule
          ),
      },
      {
        path: 'listing',
        data: {
          type: 'listing',
          icon: 'listing',
          roles: [UserRoleEnum.manager, UserRoleEnum.admin, UserRoleEnum.staff],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/listing/listing.module').then(
            (m) => m.ListingModule
          ),
      },
      {
        path: 'move',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/move/move.module').then((m) => m.MoveModule),
      },
      {
        path: 'role',
        canActivate: [AuthGuard],
        data: {
          icon: 'settings',
          roles: [UserRoleEnum.admin],
        },
        loadChildren: () =>
          import('./routes/role/role.module').then((m) => m.RoleModule),
      },
      {
        path: 'units',
        data: {
          type: 'settings',
          roles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.staff],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/unit/unit.module').then((m) => m.UnitModule),
      },
      {
        path: 'buildings',
        data: {
          type: 'settings',
          roles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.staff],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/building/building.module').then(
            (m) => m.BuildingModule
          ),
      },
      {
        path: 'locations',
        data: {
          type: 'settings',
          roles: [UserRoleEnum.manager, UserRoleEnum.staff],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/location/location.module').then(
            (m) => m.LocationModule
          ),
      },
      {
        path: 'expense-types',
        data: {
          type: 'settings',
          roles: [UserRoleEnum.manager, UserRoleEnum.staff],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/expense-types/expense-types.module').then(
            (m) => m.ExpenseTypesModule
          ),
      },
      {
        path: 'request-types',
        data: {
          type: 'settings',
          roles: [UserRoleEnum.manager, UserRoleEnum.staff],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/request-type/request-type.module').then(
            (m) => m.RequestTypeModule
          ),
      },
      // {
      //   path: 'accounts',
      //   data: { type: 'settings', roles: [UserRoleEnum.manager] },
      //   canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import('./routes/payment-method/payment-method.module').then(
      //       (m) => m.PaymentMethodModule
      //     ),
      // },
      {
        path: 'organization',
        data: { type: 'settings', roles: [UserRoleEnum.manager] },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/organization/organization.module').then(
            (m) => m.OrganizationModule
          ),
      },
      {
        path: 'employees',
        canActivate: [AuthGuard],
        data: {
          roles: [UserRoleEnum.manager, UserRoleEnum.admin],
          type: 'settings',
        },
        loadChildren: () =>
          import('./routes/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'others',
        data: {
          type: 'settings',
          roles: [UserRoleEnum.manager, UserRoleEnum.staff],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/rate/rate.module').then((m) => m.RateModule),
      },
      {
        path: 'properties',
        data: {
          type: 'properties',
          icon: 'property',
          roles: [UserRoleEnum.agent],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/properties/properties.module').then(
            (m) => m.PropertiesModule
          ),
      },
      {
        path: 'clients',
        data: {
          type: 'clients',
          icon: 'client',
          roles: [UserRoleEnum.agent],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/clients/clients.module').then(
            (m) => m.ClientsModule
          ),
      },
    ],
  },
  {
    path: 'setup',
    loadChildren: () =>
      import('./routes/setup/setup.module').then((m) => m.SetupModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./routes/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const MENU: MenuItem[] = routes[1].children!.map((r) => {
  return {
    route: r.path,
    type: r.data?.type,
    roles: r.data?.roles,
    title: r.path,
    icon: r.data?.icon,
  } as MenuItem;
});
